<template>
    <div class="auth-section">
        <div class="image-section">
            <div class="image-content">
                <!--<img class="mx-auto img-fluid left-logo mt-5" width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo">-->
                <h1 class="mt-3">Welcome to LongLeap.One</h1>
                <div class="mt-3">
                    <img class="img-fluid" src="../../assets/images/login.png" alt="">
                </div>
            </div>
        </div>
        <div class="form-section">
            <div class="form-content">
                <img id="longleap_logo" class="mx-auto img-fluid" width="230" height="65"
                src="../../assets/images/logo.png"
                alt="Logo"/>

                <h1 class="pageTitle mt-0">Forgot Password</h1>

                <el-form
                    ref="form"
                    :model="formData"
                    :rules="rules"
                    @submit.native.prevent="submitForm('form')"
                    class="mt-3"
                    label-position="top"
                >

                    <el-form-item label="Enter the potential email used for account creation" prop="email">
                        <el-input
                            v-model="formData.email"
                            type="email"
                        />
                    </el-form-item>

                    <el-button
                        class="mt-0"
                        style="width: 100%"
                        type="success"
                        :loading="loading"
                        @click="submitForm('form')">Submit
                    </el-button>

                </el-form>

                <div class="text-center mt-3">
                    Do you already have an account?
                    <router-link to="/signin">Sign In</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";

export default {
    name: "ForgotEmail",
    data() {
        return {
            formData: {
                email: '',
            },

            rules: {
                email: [
                    {required: true, message: 'Email is required', trigger: 'blur'},
                    {type: 'email', message: 'Email is not valid', trigger: 'blur'}
                ],
            },

            loading: false,
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.callToBackendApi();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        callToBackendApi() {
            this.loading = true;
            axios.post('/auth/forgot-password', {email: this.formData.email, type: 'password'})
                .then(() => {
                    Notification.success('If applicable, we have sent you a temporary password.');
                    this.$router.push({ path: "/signin", query: { email: this.formData.email }});
                })
                .catch((err) => {
                    Notification.error(err.response.data.error);
                })
                .finally(() =>{
                    this.loading = false;
                })
        },
    },
};
</script>

<style scoped>
.auth-section {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-wrap: wrap;
}

.image-section {
    width: 50%;
    background: #F3F3F3;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
}

.image-section h1, .form-section h1 {
    font-weight: 700;
    margin-top: 30px;
}

.form-section {
    width: 50%;
    background: #FFFFFF;
    padding: 50px 20px;
}

.form-section img {
    display: none;
}

@media (max-width: 991px) {
    .image-section {
        display: none;
    }
    .form-section h1 {
        font-size: 30px;
        text-align: center;
    }
    .form-content img {
        width: 200px;
        margin-bottom: 30px;
    }

    .form-section {
        width: 100%;
    }

    .form-section img {
        text-align: center;
        display: block;
    }
}
@media (max-width: 767px) {
    .form-section h1 {
        font-size: 24px;
        text-align: center;
    }
    
    .form-section {
        padding: 30px 20px;
    }
    .form-content img {
    width: 150px;
    margin-bottom: 15px;
}
}
@media (max-width: 576px) {
    .pageTitle {
        font-size: 23px !important;
    }
}
</style>